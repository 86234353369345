import * as func from './functions';
import React, {useEffect} from 'react';
const generateWindowListener = (validTarget, handleMessage) => event => {
    const message = func.isJson(event.data) ? JSON.parse(event.data) : event.data
    if (validTarget(message)) {
        handleMessage(message)
    }
}

//Custom Hook to ensure that the event listener updates if the target or handler changes
export const useWindowListener = (validTarget, handleMessage) => {
    useEffect(() => {
        const listener = generateWindowListener(validTarget, handleMessage)
        window.addEventListener('message', listener)
        return () => window.removeEventListener('message', listener)
    }, [validTarget, handleMessage])
}